import PropTypes from "prop-types"
import React from "react"
import { Column, Container, Row } from "../Grid"
import Image from "../Image"
// import Img from "gatsby-image"
import "./styles.scss"

const HeroHome = ({
  title,
  subheading,
  text,
  image,
  infographic,
  children,
}) => {
  return (
    <Container>
      <Row>
        <Column span={4}>
          <div className="hero__left">
            {title && (
              <h1 className="home__hero-title header-bar--yellow">{title}</h1>
            )}
            <div className="hero__infographic">{children}</div>
            {subheading && (
              <h2 style={{ fontWeight: "300", fontSize: "22px" }}>
                {subheading}
              </h2>
            )}
            {text && (
              <p className="easy-reading-font home__hero-text">{text}</p>
            )}
          </div>
        </Column>
        <Column className="hero__right-column" span={7}>
          <div className="hero__right">
            <Image filename={image} className="hero__image" />
          </div>
        </Column>
      </Row>
    </Container>
  )
}

HeroHome.propTypes = {
  title: PropTypes.string.isRequired,
  subheading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string,
  image: PropTypes.string.isRequired,
}

export default HeroHome
