import { Link } from "gatsby"
import React from "react"
import { Column, Container, Row } from "../components/Grid"
import HeroHome from "../components/HeroHome"
import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/Seo"
import DayOfLearning from "../images/theme/img-home-about.png"
// import Subscribe from "../components/Subscribe";
// import SubFooterImage from "../images/theme/placeholder-image.png";
import Image from "../components/Image"
import ctaArrow from "../images/theme/img-cta-arrow.png"
import amazingMeLogo from "../images/theme/logo-amazing-me.svg"
import FooterCallout from "../partials/footerCallout"

const HomePage = () => (
  <Layout location={"home"}>
    <SEO title="Home" />
    {/***************  HERO ***************/}
    <Section className="hero__home">
      <HeroHome
        title=""
        // infographic="placeholder-image.png"
        subheading={
          <span>
            Classroom Resources <br />
            for Body Confidence
          </span>
        }
        image="img-home-lead-kids.png"
      >
        <img
          className={"hero__amazing-me-logo"}
          src={amazingMeLogo}
          alt={"amazing me logo"}
        />
      </HeroHome>
    </Section>

    {/***************  CTA ***************/}
    <Section className="foster-healthy-bodies">
      <Container>
        <Link to={"/classroom-resources/"} className={"cta"}>
          Foster healthy bodies and minds with our NEW Classroom Resources!
          <img
            src={ctaArrow}
            alt={"Go to Family Resources"}
            className={"cta__arrow"}
          />
        </Link>
      </Container>
    </Section>

    {/***************  ABOUT THE PROGRAM  ***************/}
    <Section className="about vft about-vft large-vertical-margin">
      <Container>
        <Row>
          <Column span={3}>
            <img
              src={DayOfLearning}
              className="subfooter-image"
              alt="Title: Facts, Info etc."
            />
          </Column>
          <Column span={8}>
            <h2>About the Program</h2>
            <p>
              The Dove Self-Esteem Project and Discovery Education have teamed
              up to deliver new, engaging health and confidence-building
              resources for educators teaching grades 4&mdash;12. We believe a
              confident student makes for a happy, healthy, and successful
              student, which is why we have created a suite of classroom and
              family resources focused on building self-esteem, promoting
              positive body image, and countering bullying.
            </p>
            <Link to="/about" className="global__button">
              Learn More
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  Two-columns section ***************/}
    <Section className="home-first-section-columns">
      <Container>
        <Row>
          <Column className="home-columns" span={6}>
            <p className="home-columns-grades">GRADE 4&mdash;5</p>
            <p className="home-columns-title">Classroom Resources</p>
            <Image
              filename="img-home-introduction.jpg"
              className="home-columns-image"
            />
            <p className="home-columns-description">
              Empower students 4th through 5th grade to be confident inside and
              out with evidence-based classroom resources focused on health,
              positive self-esteem, and body confidence. Students will hear from
              the familiar characters of Cartoon Network's Steven Universe, as
              well as from their peers, as they explore how to be kinder to
              themselves and each other. They’ll learn to appreciate their
              bodies for what they can do, be smarter about images they see
              online, and build strategies to fight weight-based bullying and
              teasing.
            </p>
            <Link to="/classroom-resources" className="global__button">
              Learn More
            </Link>
          </Column>
          <Column className="home-columns" span={6}>
            <p className="home-columns-grades">GRADE 6&mdash;12</p>
            <p className="home-columns-title">
              Animated Topic Series{" "}
              <span style={{ marginLeft: "1rem", color: "#7F4D9B" }}>NEW!</span>
            </p>
            <Image
              filename="img-home-animated-topics.jpg"
              className="home-columns-image"
            />
            <p className="home-columns-description">
              Self-esteem and body confidence skills help young people to
              productively engage in their communities, foster healthy
              relationships, and challenge appearance ideals. These animated
              videos offer unique perspectives, focused on body confidence and
              its role in healthy self-esteem. Celebrándome explores how
              colorism in a culture influences self-identity. My Hair, My CROWN
              looks at hair as a creative expression deeply tied to identity.
              Body Confident Sport empowers female athletes to participate in
              athletic activities enjoyed by all body types.
            </p>
            <Link to="/power-of-confidence/" className="global__button">
              Learn More
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="home-second-section-columns">
      <Container>
        <Row>
          <Column className="home-columns" span={6}>
            <p className="home-columns-grades">GRADE 4&mdash;5</p>
            <p className="home-columns-title">
              Body Positivity Virtual Field Trip
            </p>
            <Image
              filename="img-vft-live-thumbnail.png"
              className="home-columns-image"
            />
            <p className="home-columns-description">
              In this virtual field trip, students will join characters from
              Cartoon Network’s Steven Universe along with other elementary
              students to explore how to be kinder to themselves and others.
            </p>
            <Link to="/virtual-field-trip" className="global__button">
              Learn More
            </Link>
          </Column>
          {/* <Column className="home-columns" span={6}>
            <p className="home-columns-grades">GRADE 6&mdash;12</p>
            <p className="home-columns-title">Self-Esteem Kits</p>
            <Image
              filename="img-home-self-steem.png"
              className="home-columns-image"
            />
            <p className="home-columns-description">
              These resource kits offer articles, tools, and activities to
              encourage adults working with students to examine the unique
              facets of body image and body confidence for young people.
            </p>
            <Link to="/classroom-resources" className="global__button">
              Learn More
            </Link>
          </Column> */}
        </Row>
      </Container>
    </Section>

    {/*/!***************  VIRTUAL FIELD TRIP ***************!/*/}
    {/*<Section className={'virtual-field-trip'}>*/}
    {/*  <Container>*/}
    {/*    <Row>*/}
    {/*    */}
    {/*    </Row>*/}
    {/*  </Container>*/}
    {/*</Section>*/}

    {/***************  EXPLORE ADDITIONAL RESOURCES ***************/}
    <Section className="home-resources-section">
      <Container>
        <h3>Explore Additional Resources</h3>
        <Row>
          <Column span={5} className={"is-offset-1 resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-master-class.png"}
            />
            <h4 className={"bold-text"}>Master Class Series</h4>
            <p>
              The Master Class videos provide educators with skills to encourage
              kindness and open discourse around the topics of body
              dissatisfaction and bullying. 
            </p>
            <Link to={"/master-class/"}>Learn More</Link>
          </Column>
          <Column span={5} className={"resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-family.png"}
            />
            <h4 className={"bold-text"}>Family Resources</h4>
            <p>
              Encourage body positivity and self-esteem at home with these
              important family resources.
            </p>
            <Link to={"/family-resources/"}>Learn More</Link>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  BODY CONFIDENCE FACT ***************/}
    <Section className={"body-confidence-fact body-confidence-fact--home"}>
      <Container>
        <Row>
          <Column span={7}>
            <h2>Body Image Insights</h2>
            <p>
              Together, we can help young people to recognize the pressures that
              can prevent them from developing self-confidence and help them
              feel more confident about their bodies.
            </p>
            <p>
              &mdash;<em>The Dove Youth Board Report, 2020 </em>
            </p>
          </Column>
          <Column
            span={4}
            className={"is-offset-1 body-confidence-fact__container"}
          >
            <div className={"body-confidence-fact__container__image"}>
              <Image filename={"img-subfooter-1.png"} className={""} />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  FOOTER CALLOUT ***************/}
    <FooterCallout />
  </Layout>
)
export default HomePage
